<template>
  <div class="d-flex justify-content-between align-items-center p-1 pr-2 pl-2 rounded w-100" v-background-1>
    <div v-if="loading || loadingCollabs">Chargement...</div>
    <div v-if="selectedCollab && selectedCollab.id" class="d-flex align-items-center p-1 rounded">
      <AppAvatar :url="selectedCollab.collaborateur_avatar" propSize="2.3rem" ref="avatar"></AppAvatar>
      <div class="mr-2 ml-2">
        <div>{{ selectedCollab.collaborateur_nom }} {{ selectedCollab.collaborateur_prenom }}</div>
      </div>
    </div>
    <div class="text-primary cursor-pointer p-1" v-if="selectedCollabId > 0" @click.stop="unSelect">Annuler</div>
    <v-popover class="w-100 d-block" v-if="!loading && !loadingCollabs && selectedCollabId == 0">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div class>
          <div v-if="selectedCollabId == 0" class="text-primary cursor-pointer p-2">{{ text }}</div>
        </div>
      </div>
      <template slot="popover">
        <div style="width: 300px">Rechercher un collaborateur</div>
        <div>
          <input-search
            ref="inputSearchCollab"
            class="mt-2"
            text="Rechercher..."
            v-model="searchText"
            :showResults="false"
            :showIcon="false"
            @input="getCollaborateurs"
            darkBackgroundMode
          ></input-search>
        </div>
        <div style="height: 200px; overflow: auto">
          <div v-if="loadingSearch" class="mt-3">Chargement...</div>
          <div v-if="!loadingSearch" class="d-flex flex-column">
            <div v-for="collab in data" :key="collab.id">
              <div
                v-if="collab.id"
                @click="select(collab)"
                class="d-flex align-items-center p-1 rounded cursor-pointer"
                :class="{ 'bg-primary text-white': selectedCollabId === collab.id }"
              >
                <AppAvatar :url="collab.collaborateur_avatar" propSize="1.4rem" ref="avatar"></AppAvatar>
                <div class="mr-2 ml-2" style="line-height: 1.1rem">
                  <div>{{ collab.collaborateur_nom }} {{ collab.collaborateur_prenom }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </v-popover>
    <div class="invalid-feedback d-block list-unstyled" v-if="errors">
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </div>
  </div>
</template>
<script>
import AppAvatar from "@/components/bases/Avatar.vue";
import InputSearch from "@/components/bases/InputSearch.vue";
import { mapGetters } from "vuex";

export default {
  components: { AppAvatar, InputSearch },
  data() {
    return {
      selectedCollabId: 0,
      selectedCollab: {},
      searchText: "",
      loadingCollabs: false,
      loadingSearch: false,
      data: [],
    };
  },
  props: {
    value: {
      Type: [Object, Array],
      Requierd: true,
    },
    errors: {
      Type: [Object, Array],
      Requierd: false,
    },
    loading: {
      Type: Boolean,
      Requierd: false,
      default: false,
    },
    text: {
      Type: String,
      default: "Selectionner un collaborateur",
    },
  },
  methods: {
    select: function (collab) {
      this.selectedCollabId = collab.id;
      this.selectedCollab = collab;
      this.searchText = "";
      this.data = [];
      this.$emit("input", collab.id);
      this.$emit("collabSelected", collab);
    },
    unSelect: function () {
      this.selectedCollabId = 0;
      this.selectedCollab = {};
      this.searchText = "";
      this.data = [];
      this.$emit("input", null);
      this.$emit("unSelect");
    },
    getCollaborateurs: function () {
      this.loadingSearch = true;
      this.$http
        .get("/collaborateurs/search/" + this.searchText)
        .then((response) => (this.data = response.data))
        .finally(() => (this.loadingSearch = false));
    },
    getCollaborateur: function () {
      this.loadingCollabs = true;
      this.$http
        .get("/collaborateurs/" + this.selectedCollabId)
        .then((response) => (this.selectedCollab = response.data))
        .finally(() => (this.loadingCollabs = false));
    },
  },
  computed: {
    ...mapGetters({
      //data: "collaborateurs/all"
    }),
  },
  mounted() {
    if (this.value) {
      this.selectedCollabId = this.value;
      this.getCollaborateur();
    }
  },
};
</script>
<style lang="css"></style>
